.container{
    width: 100% !important;
    max-width: 9999px!important;
    position: relative;
    background-color: rgb(255, 255, 255);
    padding-top: 11px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-bottom: 5px !important;
}

.container_mobile{
    position: fixed;
    width: 100% !important;
    max-width: 9999px!important;
    top: 10px;
    z-index: 999;
    /* background-color: rgb(255, 255, 255); */
}

.mobile_button{
    position: fixed;
    z-index: 999;
    right: 14px;
    /* top: 10px; */
    bottom: 60px;
    width: 42px;
    height:42px;
    border-radius: 100% !important;
    background-color: rgba(120, 207, 241, 0.279);
}

.glIcon{
    margin-top: -6px;
    margin-left: -11px;
}

.button{
    margin-top: 0px !important;
    margin-left: 5px !important;
    margin-right: 5px !important;
    margin-bottom: 0px !important;
}

.popover{
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
    margin-top: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 0px !important;
}

.inline{
    display: flex;
    align-items: flex-start;
    justify-content: left;
}

.operator{
    margin-left: 4px !important;
}

.opLabel{
    font-size: small;
}




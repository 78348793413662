.container{
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 100%;
}

.inner_container{
    display: flex;
    justify-content: center;
    max-width: 100%;
}

.calendarItem{
    cursor: pointer;
    background-color: white;
    color: #000000;
    display: flex;
    align-items: center;
    white-space: pre-wrap;
}

.calendarItem:hover{
  background-color: #f1f3f4 !important;
}

.calendarItem:active{
  background-color: #f1f3f4;
}

.redDot {
    min-height: 15px;
    min-width: 15px;
    /* background-color: rgb(253, 185, 77); */
    border-radius: 100%;
    margin-top: 2px;
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 2px;
  }

.title{
  font-size: x-large;
  margin-bottom: 5px;
  color: #3c4043;
  white-space: nowrap;
  font-weight: bold;
}

.title_mobile{
  margin-bottom: 5px;
  font-size: large;
  color: #3c4043;
  white-space: nowrap;
  font-weight: bold;
}

.container{
  display: flex;
  flex-direction: column;
}

.elem_title{
  padding-left: 10px;
  font-size: large;
}

.elem_prop{
  padding-left: 15px;
  margin-top: 5px;
  font-size: medium;
  color: #696969;
}

.link{
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #3c4043;
  padding-top: 10px;
  padding-bottom: 10px;
}

.link:hover{
  color: #3c4043;
  background-color: #dadce0;
  text-decoration: none;
}

.link:focus{
  color: #3c4043;
  background-color: #dadce0;
  text-decoration: none;
}

.styleDiv{
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 5px;
  width: 26px !important;
  height: 26px !important;
  border-radius: 100%;
}

.yearLegend{
  margin-left: 14px;
}

.yearLegend_mobile{
  margin-left: 14px;
  flex-direction: column;
}

.stDiv1{
  background-color: #d4d4d4;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  border-radius: 100%;
  margin-right: 4px;
}

.stDiv2{
  background-color: #e5e5e5;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  border-radius: 100%;
  margin-right: 4px;
}

.stDiv3{
  background-color: #f2f2f2;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  border-radius: 100%;
  margin-right: 4px;
}

.mobileLegend{
  font-size: medium;
  max-width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.inner_container{
  display: flex;
  flex-direction: row;
}

.container{
    background-color: #e5eaee;
    padding-top: 5px;
    padding-left: 0px;
    padding-right: 10px;
    padding-bottom: 5px;
    margin-bottom: 0px;
    max-width: 100%;
}

.container_mobile{
    background-color: #e5eaee;
    padding-top: 5px;
    padding-left: 0px;
    padding-right: 10px;
    padding-bottom: 5px;
    margin-bottom: 0px;
    max-width: 100%;
}

.list_item{
    padding-left: 10px;
}

.list_item:hover{
    background-color:#f1f3f4 !important;
}

.link:hover{
    text-decoration: none;
}

.link:focus{
    text-decoration: none;
}

.styleDiv{
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: 24px !important;
    height: 24px !important;
    border-radius: 100%;
}

.styleDiv_mobile{
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: 16px !important;
    height: 16px !important;
    border-radius: 100%;
}

.styleCenter{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
}

.styleCenter_mobile{
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
}

.styleCenterProperty{
    display: flex;
    flex-direction: column;
    height: auto;
}

.styleCenterProperty_desktop_small{
    display: flex;
    flex-direction: column;
    height: auto;
}

.styleCenterProperty_mobile{
    display: flex;
    flex-direction: column;
    height: auto;
}

.titleStyle{
    font-size: large !important;
    padding-bottom: 5;
    font-weight: 600;
    color: #3c4043;
}

.titleStyle_desktop_small{
    font-size: medium !important;
    padding-bottom: 5;
    font-weight: 600;
    color: #3c4043;
}

.titleStyle_mobile{
    font-size: x-small !important;
    padding-bottom: 5;
    font-weight: 600;
    color: #3c4043;
}

.slimText{
    font-size: medium;
    color: #696969;
    font-weight: lighter;
    padding-bottom: 5;
}

.slimText_desktop_small{
    font-size: small;
    color: #696969;
    font-weight: lighter;
    padding-bottom: 5;
} 

.slimText_mobile{
    font-size: x-small;
    color: #696969;
    font-weight: lighter;
    padding-bottom: 5;
}

.propertyStyle{
    font-size: small;
    color: #3c4043;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: 600;
}

.propertyStyle_desktop_small{
    font-size: x-small;
    color: #3c4043;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: 600;
}

.propertyStyle_mobile{
    font-size: xx-small;
    color: #3c4043;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: 600;
}

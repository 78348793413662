.buttons{
    display: flex;
    justify-content: flex-start;
    margin-left: 0px;
    /* margin-right: 2px; */
    color: #3c4043;
    width: 100%;
}

.buttons:hover{
    background-color: #f1f3f4;
    color: #3c4043;
    text-decoration: none;
}

.glNotAvailable{
    font-style: italic !important;
    color: #3c4043 !important;
    text-decoration: none !important;
}
.glNotAvailable:hover{
    text-decoration: none !important;
    color: #3c4043 !important;
}

.glSwitchButton{
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    color: #5f6368;
}

.glSwitchButton:hover{
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    color: #dadce0 ;
}

.glFlex{
    display: flex;
    align-items: flex-start;
    justify-content: right;
  
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }

.glCardHeaderRight{
    margin-left: auto !important;
}

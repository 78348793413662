.container{
    background-color: #e5eaee;
    padding-top: 0px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 0px;
    max-width: 100% !important;
  }


.glMaxWidth{
    max-width: 1000px;
}
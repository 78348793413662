.container{
    display: flex;
    align-items: flex-start;
    background-color: rgb(255, 255, 255, 0.3) !important;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    max-width: 100%;
  }

.glButtonToolbar{
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }

.glDropdown{
  padding-top: 0px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  padding-bottom: 7px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.glDropdown_mobile{
  height: 24px!important;
  padding-top: 0px !important;
  padding-left: 1px !important;
  padding-right: 1px !important;
  padding-bottom: 1px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.glButton{
  height: 32px!important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 0px !important;
  margin-left: 2px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
}

.glButton_mobile{
  height: 30px!important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 0px !important;
  margin-left: 2px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
}
  
.glIcon{
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-top: 0px !important;
  margin-left: 6px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  }
  
.glIcon_mobile{
    width: 12px !important;
    height: 12px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-top: 0px !important;
    margin-left: 4px !important;
    margin-right: 0px !important;
    margin-bottom: 2px !important;
  }

.op1{
    opacity: 1;
 }

.monthContainer{
  display: inline-grid;
  grid-template-columns: 30% 30% 30%;
  grid-template-rows: 25% 25% 25% 25%;
  justify-content: center;
  padding: 1px;
  width: 100%;
  box-sizing: border-box;
 }

.monthButton{
  line-height: 3em;
  text-align: center;
  width: 100%;
 }

.yearContainer{
  display: inline-grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: 16% 16% 16% 16% 16% 16%;
  justify-content: center;
  padding: 1px;
  width: 100%;
  box-sizing: border-box;
 }

.yearButton{
  line-height: 1em;
  text-align: center;
  padding: 2px!important;
  width: 100%;
 }

.weekContainer{
  min-width: 400px!important;
  display: inline-grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-template-rows: 9% 9% 9% 9% 9% 9% 9% 9% 9% 9% 9%;
  justify-content: center;
  padding: 1px;
  width: 100%;
  box-sizing: border-box;
 }

.weekContainer_mobile{
  min-width: 220px!important;
  max-width: 220px!important;
  display: inline-grid;
  grid-template-columns: 30% 30% 30%;
  /* grid-template-rows: ; */
  justify-content: center;
  padding: 1px;
  width: 100%;
  box-sizing: border-box;
  /*black*/
 }

.popOverWideWeek{
  min-width: 425px!important;
  opacity: 1;
 }

.popOverWideWeek_mobile{
  min-width: 250px!important;
  max-width: 250px!important;
  max-height: 400px !important;
  overflow-y: scroll;
  opacity: 1;
  /*red*/
 }

.weekButton{
  line-height: 1em;
  text-align: center;
  padding: 2px!important;
  width: 100%;
 }

 .label{
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
  padding-top: 3px !important;
  padding-left: 1px !important;
  padding-right: 1px !important;
  padding-bottom: 0px !important;
 }

.label_mobile{
  font-size: small !important;
  margin-top: 1px !important;
  margin-bottom: 1px !important;
  margin-left: 1px !important;
  margin-right: 1px !important;
  padding-top: 3px !important;
  padding-left: 1px !important;
  padding-right: 1px !important;
  padding-bottom: 0px !important;

  white-space: nowrap !important;
}


.glToolTip{
  border-Radius: 8px !important;
  color: #ffffff !important;
 }

.popup_nav{
  display: flex; 
  justify-content: space-evenly; 
  align-items: center;
 }

.buttonMonth{
  height: auto; 
  border: none !important;
  background: none !important; 
  color: #3c4043 !important
 }

 .buttonMonth:hover{
  height: auto; 
  border: none !important;
  text-decoration: none !important;
  background: none !important; 
 }

 .buttonMonth:focus{
  height: auto; 
  border: none !important;
  text-decoration: none !important;
  background: none !important; 
  color: #6c757d !important;
  box-shadow: none !important;
 }

.buttonYear{
  height: auto; 
  border: none !important;
  background: none !important; 
  color: #3c4043 !important
 }

 .buttonYear:hover{
  height: auto; 
  border: none !important;
  text-decoration: none !important;
  background: none !important; 
 }

 .buttonYear:focus{
  height: auto; 
  border: none !important;
  text-decoration: none !important;
  background: none !important; 
  color: #6c757d !important;
  box-shadow: none !important;
 }

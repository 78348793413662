.container{
    color: #3c4043 ;
    position: relative;
    background-color: white !important;
    padding-top: 0px;
    padding-bottom: 5px;
    max-width: 100% !important;
  }

.container_mobile{
    background-color: white !important;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    max-height: 100vh !important;
    max-width: 100%;
  }

.space{
  height: 10px;
}

.glCard{
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.glFacets{
  padding-top: 3px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  margin-right: 50px;
}

.glIcon{
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 0px;
  margin-left: 0px;
}

.glButtonn{
  padding: 0px;
  margin: 5px;
  margin-left: 0px;
  width: 22px!important;
  height: 22px!important;
}

.glActiveFilterLabel{
  margin-left: 5px;
}

.glContainer {
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-template-rows: auto;
    grid-template-areas: "header header" "summary summary" "sidebar main" "footer footer";
  }
  
.glMobileview {
    grid-template-columns: 0fr 1fr;
  }
  

.glHeader {
    grid-area: header;
    background-color: rgb(255, 153, 0);
  }
  
.glSummary {
    grid-area: summary;
    background-color: #e5eaee;
  }

.glSidebar {
    grid-area: sidebar;
    visibility: visible;
    background-color: #e5eaee;
    padding-top: 4px;
    width: 300px;
  }

.glFloatingSidebar {
    overflow-y: scroll;
    position: fixed;
    visibility: visible;
    top: 0px;
    height: 100%;
    background-color: rgb(255, 255, 255);
    width: 300px;
    z-index: 99999;
    animation: side-in-left .7s;
  }

.glHiddenSidebar{
    display: None;
    top: 12px;
    left: 12px;
    width: 0px !important;
    height: 0px !important;
    background-color: rgb(166, 173, 101);
    width: 300px;
  }

  
.glMain {
    grid-area: main;
    background-color: #e5eaee;
  }

.glFooter {
    grid-area: footer;
    background-color: #e5eaee;
    /*background-color: rgb(126, 124, 253);*/
  }

.glIcons{
    width: 46px;
    height: 44px;

    padding-left: 0px!important;
    padding-right: 0px!important;
    padding-top: 0px!important;
    padding-bottom: 0px!important;

    margin-left: -10px;
    margin-right: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
  }

.fixBottomRight{
    /* display: flex;
    align-items: flex-start; */
    border-Radius:100% !important;
    border-style: hidden !important;

    width: 46px;
    height: 44px;

    position: fixed;
    /* visibility: visible; */

    padding-left: 10px!important;
    padding-right: 10px!important;
    padding-top: 10px!important;
    padding-bottom: 10px!important;
    
    bottom: 12px;
    right: 12px;

    z-index: 9999;
  }

@keyframes side-in-left {
    from {
      transform: translateX(-100%);
      
    }
    to {
      transform: translateX(0);
    }
  }

.divInfo{
  visibility: hidden;
  font-size: xx-small !important;
  padding-left: 5px;
  }
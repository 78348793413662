/* globaly used to modify Bootstrap defaults */
.btn:hover{
  background-color: #f1f3f4 !important;
  border: 1px solid rgba(0,0,0,.125);
  color: #3c4043 !important;
}

.btn:focus{
  background-color: #f1f3f4 !important;
  box-shadow: none !important;
}

.btn:disabled{
  background-color: rgba(241, 242, 243, 1.0) ;
  opacity: 1.0;
  border: 1px solid rgba(0,0,0,.125) ;
}

.btn{
  color: #3c4043 !important;
  border: 1px solid rgba(0,0,0,.125);
  background-color: white;
  margin-right: 2px;
}


.container{
    background-color:#ffffff !important;
    /* padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px; */
    /* padding-bottom: 5px;
    max-width: 100%; */
  }


.glButton{
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 3px;
    margin-bottom: 0px;
    background-color: rgba(255, 255, 255, 1.0);
  }

.glButton:disabled{
  background-color: rgba(241, 242, 243, 1.0) !important;
  opacity: 1.0 !important;
  border: 1px solid rgba(0,0,0,.125) !important;
}

.glIcon{
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 0px;
    margin-left: 0px;
  }

.glIcon_mobile{
    width: 12px !important;
    height: 12px !important;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 0px;
    margin-left: 0px;
  }

.glToolTip{
    border-Radius: 8px !important;
    color: #ffffff !important;
   }


.container{
  background-color: #e5eaee;
  padding-top: 5px;
  padding-bottom: 0px;
  padding-left: 1px;
  padding-right: 1px;
  max-width: 100% !important;
}

.container_mobile{
  background-color: #e5eaee;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 1px;
  padding-right: 1px;
  max-width: 100% !important;
}

.labelTotal{
  color: #3c4043;
  text-align: left;
  padding-left: 0px;
  padding-top: 6px;
  padding-bottom: 5px;
  margin-top: 5px;
  margin-left: 10px;
  font-size: 24px;
  font-weight: bold;
  overflow: hidden;
  white-space: normal;
}

.labelTotalMedium{
  color: #3c4043;
  text-align: left;
  padding-left: 0px;
  padding-top: 6px;
  padding-bottom: 5px;
  margin-top: 5px;
  margin-left: 10px;
  font-size: 20px;
  font-weight: bold;
  overflow: hidden;
  white-space: normal;
}

.labelSmall{
  color: #696969 ;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 0px;
  margin-bottom: -6px;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
}

.radialDiv{
  padding-left: 0px;
  padding-top: 0px;
  margin-top: -10px;
}

.labelRadial{
  color: rgb(251, 10, 10);
}

.labelNoDetail{
  margin-top: 30px; /* half this element's height */
}

.colRight{
  text-align: right;
}

.colVCenter{
  position: relative;
}

.divRadial{
  width: 110px;
}

.divBox{
  display: flex;
}



.totalDiv{
    display: flex;
    align-items: flex-start;
    justify-content: left;
    margin-top: 5px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 10px;
    overflow: hidden;
}
.totalDiv_mobile{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 10px;
  overflow: hidden;
}



.legendDiv{
  display: flex;
  align-items: flex-start;
  justify-content: left;
  font-size: small;
  margin-top: 5px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 10px;
  overflow: hidden;
}
.legendDiv_mobile{
  display: flex;
  align-items: flex-start;
  justify-content: left;
  font-size: x-small;
  margin-top: 5px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 10px;
  /* overflow: hidden; */
}


.legendItem{
  color: #3c4043;
  white-space: nowrap;
  margin-top: -4px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 10px;
}


.divLegendGrid{
  height: 100px!important;
  max-height: 100px!important;

  width: 100%;

  display: inline-grid;
  grid-template-columns: 55% 45%;
  grid-template-rows: 20% 20% 20% 20% 20%;
  justify-content: center;

  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
}
.divLegendGrid_mobile{
  height: 100px!important;
  max-height: 100px!important;

  width: 100%;

  display: inline-grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 20% 20% 20% 20% 20%;
  justify-content: center;

  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
}

.divLegend{
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
}
.divLegend_mobile{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
}


.divDoughnut{
  height: 110px!important;
  max-height: 110px!important;
  width: 165px!important;
  max-width: 165px!important;
  margin-left: -30px;
  margin-bottom: 10px;
}

.divDoughnut_mobile{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 110px!important;
  max-height: 110px!important;
}


.image{
  padding-top: 0px;
  margin-top: -10px;
}

.cardHeader{
  padding: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
}


.col{
  background-color: white !important;
  min-height: 150px;
}
.col_mobile{
  width: 100%;
  text-align: center !important;
  float: none;
  margin-top: 5px;
  padding-bottom: 10px;
  background-color: white !important;
}
.colStyle{
  width: 100%;
}

.row{
  margin: 0px;
  background-color: #e5eaee;
}

.glIcons{
  width:64px;
  height:64px;
}

.gradientItem{
  height: 50px !important;
  max-height: 50px !important;
}

.container{
    display: flex;
    align-items: flex-start;
    background-color: rgb(255, 255, 255, 0.3) !important;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    max-width: 100%;
}

.label{
    padding-top: 3px;
    margin-top: 0px !important;
    padding-left: 10px !important;
    margin-right: 5px !important;
    padding-bottom: 0px !important;
}

.label_mobile{
  padding-top: 3px;
  font-size: small !important;
  margin-top: 0px !important;
  padding-left: 2px !important;
  margin-right: 2px !important;
  padding-bottom: 0px !important;
}

.glDropdown{
  padding-top: 0px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  padding-bottom: 7px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.glDropdown_mobile{
  padding-top: 0px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  padding-bottom: 3px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.glButton{
  width: 32px !important;
  height: 31px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 0px !important;
  margin-left: 2px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
}

.glButton_mobile{
  width: 32px !important;
  height: 31px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 0px !important;
  margin-left: 2px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
}

.glIcon{
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-top: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  }

.glIcon_mobile{
    width: 12px !important;
    height: 12px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-top: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 0px !important;
  }

.glToolTip{
    border-Radius: 8px !important;
    color: #ffffff !important;
   }


.container{
    position: relative;
    /* left: 4px; */
    background-color: white !important;
    padding-top: 5px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 5px;
    margin-bottom: 0px;
    margin-left: 0px;
    max-width: 100% !important;
    min-height: 750px;
  }

.title{
  font-size: x-large;
  color: #3c4043;
  white-space: nowrap;
  font-weight: bold;
}

.title_mobile{
  font-size: large;
  color: #3c4043;
  white-space: nowrap;
  font-weight: bold;
}

.container_mobile{
    background-color: white !important;
    padding-top: 5px;
    padding-left: 0px;
    padding-right: 10px;
    padding-bottom: 5px;
    margin-bottom: 0px;
    width: 100vw !important;
}
  
.inside{
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    margin-right: 50px;
  }


.glButton{
    width: 24px !important;
    height: 24px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-top: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 0px !important;
  }

.glButton_mobile{
  width: 10px !important;
  height: 10px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
}


.glPageButton{
  padding-top: 2px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  padding-bottom: 2px !important;
  margin-left: 1px !important;
  margin-top: 0px !important;
  margin-right: 1px !important;
  margin-left: 0px !important;
}

.glPageButton_mobile{
  width: 20px !important;
  height: 20px !important;
  padding-top: 0px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  padding-bottom: 0px !important;
  margin-left: 1px !important;
  margin-top: 0px !important;
  margin-right: 1px !important;
  margin-left: 0px !important;
}

.glPageLabel{
  margin-top: 0px !important;
  padding-left: 0px !important;
  margin-right: 0px !important;
  padding-bottom: 0px !important;
 }

.glPageLabel_mobile{
  font-size: small !important;
  margin-top: -1px !important;
  margin-bottom: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-top: 0px !important;
  padding-left: 0px !important;
  white-space: nowrap !important;
  padding-bottom: 0px !important;
}


.glIcon{
  width: 18px !important;
  height: 18px !important;
  padding-top: 0px !important;
  padding-left: 3px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  margin-top: -4px !important;
  margin-left: 0px !important;
}

.glIcon_mobile{
  width: 14px !important;
  height: 14px !important;
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  margin-top: -9px !important;
  margin-left: 2px !important;
}

.listItems{
  padding-top: 5px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 5px;
  margin-left: 1px;
  margin-right: 1px;
}

.glToolTip{
  border-Radius: 8px !important;
  color: #ffffff !important;
 }

.divPagination{
  display: flex;
  align-items: flex-start;
}

.divPageRange{
  display: flex;
  align-items: flex-start;
  margin-left: 10px;
  margin-right: 2px;
}

.label{
  margin-top: 0px !important;
  padding-left: 0px !important;
  margin-right: 0px !important;
  padding-bottom: 0px !important;
 }

.label_mobile{
  font-size: small !important;
  margin-top: 2px !important;
  margin-bottom: 4px !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
  padding-top: 2px !important;
  padding-left: 0px !important;
  margin-right: 3px !important;
  white-space: nowrap !important;
  padding-bottom: 0px !important;
}


.glPageRangeLabel{
  padding-top: 3px !important;
  padding-bottom: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  margin-top: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
}

.glPageRangeLabel_mobile{
  font-size: small !important;
  padding-top: 3px !important;
  padding-bottom: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  margin-top: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  }

.paginationChoice{
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
  }

.paginationChoice_mobile{
  font-size: small !important;
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.glDropdown{
  padding-top: 0px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  padding-bottom: 7px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.glDropdown_mobile{
  padding-top: 0px !important;
  padding-left: 1px !important;
  padding-right: 1px !important;
  padding-bottom: 1px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.paddingLeft{
  padding-left: 10px !important;
}

.paddingLeft5{
  padding-left: 5px !important;
}

.paddingRight{
  padding-right: 10px !important;
}

.paddingRight20{
  padding-right: 20px !important;
}

.download_container{
  padding-left: 10px;
}

.download_icon{
  width: 20px;
  height: 14px;
  padding-right: 5px;
  margin-bottom: 4px;
}

.download_button{
  margin-bottom: 6px;
}



.box{
    border-style: solid none solid solid;
    border-width: 1px;
    border-color: #dadce0; 
    margin-left: 10px;
}

.container{
    display: flex;
    flex-direction: row;
    flex: 1;
    /* text-align: center; */
    /* min-height: 750px; */
}

.day_container{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.pivot{
    border-color: #dadce0; 
    border-style: none solid none none; 
    border-width: 1px;    
    min-height: 750px;
}

.inner_pivot{
    text-align: center;
}

.elem_container{
    display: flex;
    flex-direction: column;
    min-height: 200px;
    /* border-color: #dadce0; 
    border-style: solid; 
    border-width: 1px; */
}

.elem{
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    color: #3c4043;
    margin-top: 5px;
    margin-left: 3px;
    width: 96%;
    border-color: #dadce0; 
    border-style: solid solid solid solid; 
    border-width: 1px;
}

.elem_mobile{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    cursor: pointer;
    color: #3c4043;
    margin-top: 5px;
    margin-left: 1px;
    width: 94%;
    border-color: #dadce0; 
    border-style: solid solid solid solid; 
    border-width: 1px; 
    max-width: 45px !important;
}

.elem:hover{
    color: #3c4043;
    background-color: #f1f3f4;
    text-decoration: none;
}

.elem_title{
    /* padding-left: 10px; */
    font-size: small;
}

.elem_title_mobile{
    font-size: xx-small;
    white-space: pre-wrap;
}

.elem_prop{
    padding-left: 15px;
    font-size: small;
    color: #696969;
}

.link{
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #3c4043;
    padding-top: 10px;
    padding-bottom: 10px;
}

.link:hover{
    color: #3c4043;
    background-color: #dadce0;
    text-decoration: none;
}

.link:focus{
    color: #3c4043;
    background-color: #dadce0;
    text-decoration: none;
}

.styleDiv{
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 2px;
    margin-bottom: 0px;
    margin-right: 4px;
    margin-left: 2px;
    /* margin-right: 5px; */
    min-width: 14px !important;
    min-height: 14px !important;
    border-radius: 100%;
}

.styleDiv_mobile{
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 4px;
    margin-bottom: 0px;
    margin-right: 4px;
    margin-left: 2px;
    /* margin-right: 5px; */
    min-width: 10px !important;
    min-height: 10px !important;
    border-radius: 100%;
}

.styleDiv2{
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 5px;
    min-width: 26px !important;
    min-height: 26px !important;
    border-radius: 100%;
}

.button{
    cursor: pointer;
    color: #3c4043;
}
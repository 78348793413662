.container{
    position: relative;
    background-color: #ffffff;
    /* left: 16px; */
    padding-top: 5px;
    padding-left: 0px;
    padding-right: 10px;
    padding-bottom: 5px;
    margin-bottom: 50px;
    max-width: 100%;
  }

  .calendar{
    background-color: #1177ca;
  }

.container{
    background-color: #ffffff;

    display: flex;
    align-items: flex-start;
    justify-content: left;

    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;

    min-height: 14px;
    height: 14px !important;
    max-width: 100%;
  }

.barTotal{
    color: #3c4043;
    font-size: small;
    margin-top: -5px;
    white-space: nowrap;
    min-width: 100px;
 }

.glToolTip{
  font-size: small;
  border-Radius: 8px !important;
  color: #ffffff !important;
 }
.container{
    /* background-color: red; */
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap;
    border-color: #dadce0; 
    /* border-style: solid; */
    /* border-width: 1px;  */
    max-width: 100%; 
    min-height: 750px;
}

.container_mobile{
    /* background-color: red; */
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap;
    border-color: #dadce0; 
    /* border-style: solid; */
    /* border-width: 1px;  */
    max-width: 100%; 
    /* min-height: 750px; */
}

.month_container{
    /* background-color: teal; */
    text-align: center;
    display: flex;
    flex-direction: column;
    border-color: #dadce0; 
    padding-top: 10px;
    margin-left: 20px;
    width: auto;
    height: 100%;
}

.month_title{
    padding-bottom: 10px;
}

.week_container{
    display: flex;
    flex-direction: column;
    width: auto;
}

.days_container{
    width: auto;
    display: flex;
    flex-direction: row;
}

.button{
    background-color: white !important;
    border: none !important;
    border-radius: 100% !important;
    width: 46px;
    height: 46px;
}

.button_full1{
    background-color: #d4d4d4 !important;
    border-radius: 100% !important;
    border: none !important;
    width: 46px;
    height: 46px;
}
.button_full2{
    background-color: #e5e5e5 !important;
    border-radius: 100% !important;
    border: none !important;
    width: 46px;
    height: 46px;
}
.button_full3{
    background-color:  	#f2f2f2 !important;
    border-radius: 100% !important;
    border: none !important;
    width: 46px;
    height: 46px;
}

.button:disabled{
    opacity: 0.3 !important;
}

.elem_title{
    /* padding-left: 10px; */
    font-size: medium;
}

.elem_prop{
    padding-left: 15px;
    font-size: small;
    color: #696969;
}

.link{
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #3c4043;
    padding-top: 10px;
    padding-bottom: 10px;
}

.link:hover{
    color: #3c4043;
    background-color: #dadce0;
    text-decoration: none;
}

.link:focus{
    color: #3c4043;
    background-color: #dadce0;
    text-decoration: none;
}

.styleDiv{
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 5px;
    min-width: 26px !important;
    min-height: 26px !important;
    border-radius: 100%;
}
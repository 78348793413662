.container{
    position: relative;
    padding-left: 4px !important;
    padding-top: 4px !important;
    background-color: #e5eaee;
    max-width: 100%!important;
  }

.container_mobile{
    position: relative;
    padding-left: 0px !important;
    padding-top: 5px !important;
    background-color: #e5eaee;
    max-width: 100%!important;
  }

.glMain{
    background-color: #e5eaee!important;
    margin-top: 5px !important; 
    margin-bottom: 5px !important; 
    margin-left: 0px !important; 
    margin-right: 0px !important; 
  }

.glMainBody{
    background-color: #ffffff!important;
    min-height: 250px;
  }

.glRightCol{
    margin-left: auto;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
  
.glRow{
    display: flex;
    align-items: flex-start;
    justify-content: right;
  
    padding-top: 0px;
    padding-left: 0px !important;
    padding-right: 10px;
    padding-bottom: 0px;
  
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }


.colorTest{
  background-color: #525455;
}
.box{
    border-color: #dadce0; 
    border-style: none none solid solid; 
    border-width: 1px; 
    margin-left: 10px;
}

.container{
    /* background-color: red; */
    display: flex;
    text-align: center;
    /* justify-content: center; */
    /* justify-content: flex-start; */
    flex-flow: row wrap;
    max-width: 100%; 
    /* min-height: 750px;  */
}

.pivot{
    border-color: #dadce0; 
    border-style: solid solid none none; 
    border-width: 1px;    
}

.day_container{
    /* background-color: yellow; */
    /* border-color: #dadce0; 
    border-style: solid; 
    border-width: 1px;  */
    display: flex;
    flex-direction: column;
    flex: 1;
}

.day{
    border-color: #dadce0; 
    border-style: solid solid none none; 
    border-width: 1px;    
}

.day_disabled{
    border-color: #dadce0; 
    border-style: solid solid none none; 
    border-width: 1px; 
    background-color: #fafafa;
}

.elem_container{
    display: flex;
    flex-direction: column;
    min-height: 180px;
    /* border-color: #dadce0; 
    border-style: solid; 
    border-width: 1px; */
}

.elem{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    cursor: pointer;
    color: #3c4043;
    margin-top: 5px;
    margin-left: 3px;
    width: 96%;
    border-color: #dadce0; 
    border-style: solid solid solid solid; 
    border-width: 1px;
}

.elem_mobile{
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    color: #3c4043;
    margin-top: 5px;
    margin-left: 2px;
    width: 92%;
    border-color: #dadce0; 
    border-style: solid solid solid solid; 
    border-width: 1px;
}

.elem_mobile:hover{
    color: #3c4043;
    background-color: #f1f3f4;
    text-decoration: none;
}

.elem:hover{
    color: #3c4043;
    background-color: #f1f3f4;
    text-decoration: none;
}

.elem_title{
    font-size: medium;
    /* white-space: nowrap; */
}

.elem_title_small{
    font-size: x-small;
}

.elem_prop{
    padding-left: 15px;
    margin-top: 5px;
    font-size: small;
    color: #696969;
}

.styleDiv{
    padding-top: 0px;
    padding-bottom: 0px;
    /* margin-top: 6px; */
    margin-bottom: 0px;
    margin-right: 4px;
    margin-left: 2px;
    /* margin-right: 5px; */
    min-width: 14px !important;
    min-height: 14px !important;
    border-radius: 100%;
}

.styleDiv_mobile{
    padding-top: 0px;
    padding-bottom: 0px;
    /* margin-top: 3px; */
    margin-bottom: 0px;
    margin-right: 4px;
    margin-left: 2px;
    /* margin-right: 5px; */
    min-width: 10px !important;
    min-height: 10px !important;
    border-radius: 100%;
}

.link{
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #3c4043;
    padding-top: 10px;
    padding-bottom: 10px;
}

.link:hover{
    color: #3c4043;
    background-color: #dadce0;
    text-decoration: none;
}

.link:focus{
    color: #3c4043;
    background-color: #dadce0;
    text-decoration: none;
}

.styleDiv2{
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 5px;
    min-width: 26px !important;
    min-height: 26px !important;
    border-radius: 100%;
}
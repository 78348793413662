.container{
    background-color: #ffffff;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 5px;
    padding-right: 5px;

    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;

    min-height: 110px;
    height: 110px !important;
    max-width: 100%;
  }


.divFlex{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    overflow: hidden;
}

.gradientLine{
    height: 14px!important;

    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;

    margin-top: 0px;
    margin-bottom: 3px;
    margin-left: 0px;
    margin-right: 0px;
}

.glIcon{
    height: 12px!important;
    width: 12px!important;
    
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;

    margin-top: -13px;
    margin-left: 0px;
  }

.pageButton{
    height: 16px!important;
    width: 18px!important;
    
    padding-top: 0px;
    padding-bottom: 5px !important;
    padding-left: 0px;
    padding-right: 0px;

    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 7px;
  }

.pageButton:focus{
    box-shadow: none !important;
  }

  .labelRange{
    height: 30px;
    color: #8d8b8b;
    text-align: right;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 0px;
    margin-bottom: -6px;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
  }

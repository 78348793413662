.item{
    /*width: 600px !important;*/
    cursor: pointer;
    padding-left: 10px;
    background-color: #c2c2c2;
    color: '#0000';
    opacity: 1 !important;
}

.item:hover{
    /*width: 600px !important;*/
    background-color: #757474;
    color: #ffffff !important;
    text-decoration: none;
    cursor: pointer;
}